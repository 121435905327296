.App {
  text-align: center;
}
.mnp-logo{
  margin-right: 40px;
}
.mnp-scholarship-page{
  max-width: 825px;
  display: flex;
  width: 100%;
  flex-direction: column;
  margin: auto;
  background: white;
  box-shadow: 0px 0px 15px 0px #00000014;
  margin-top: -1px;
  box-sizing: border-box;
}
@media screen and (max-width: 824px){
  .mnp-scholarship-page{
    max-width: none;
  }
}
@media screen and (max-width: 520px){
  body{
    font-size: 17px;
  }
}
@media screen and (max-width: 400px){
  body{
    font-size: 16px;
  }
}
@media screen and (max-width: 360px) {
  body {
    font-size: 15px;
  }
}
.mnp-scholarship-description{
  padding: 30px;
  padding-top: 0px;
  position: relative;
}
.mnp-scholarship-description:before {
  content: '';
  position: absolute;
  top: -26px;
  height: 1px;
  width: calc(100% - 29px);
  background-image: linear-gradient(45deg, #26468a, transparent 56%);
}
header{
  padding: 30px;
  padding-bottom: 0px;
}
.image-banner{
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 70px;
}
.logo{
  width: 166px;
}
@media screen and (max-width: 431px) {
  .logo {
    width: 115px;
  }
}
