body {
  margin: 0;
  font-family: 'Source Serif Pro', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  box-sizing: border-box;
  font-size: 19px;
  color: #242929;
  background: #fafafa;
}

html, #root{
  height: 100%;
}

#root{
  background-image: url("./bg5.png");
  background-size: cover;
  background-repeat: no-repeat;
  display: inline-block;
  width: 100%;
}

h1,h2,h3,h4{
  color: black;
}

p {
  color: #2f2f2e;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
